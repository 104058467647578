import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import Search from './pages/search'
import Get from './pages/get'
import NotFound from './pages/not-found'
import LayoutError from './components/layout-error'

const App: React.FC = () => {
  return (
    <HashRouter>
      <ErrorBoundary fallback={<LayoutError />}>
        <Routes>
          <Route path='/:trackingCode' element={<Get />} />
          <Route path='/' element={<Search />} />
          <Route element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </HashRouter>
  )
}

export default App
