import axios from 'axios'

import { ApiResponse, SearchRequest, GetRequest } from 'types'

const getBaseUrl = () => (window?._env_?.TRACKING_API_URL ? window._env_.TRACKING_API_URL : 'http://localhost:5200/tracking')

const api = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
})

api.defaults.validateStatus = status => status <= 400

const Api = {
  get: (request: GetRequest): Promise<ApiResponse> => api.post(`/v1/get`, request),
  search: (request: SearchRequest): Promise<ApiResponse> => api.post(`/v1/search`, request),
}

export default Api
